<template>
    <KCourse loader-class="MBcont" course-id="7" title="Детская голова" :items="items">
        <div class="MBtextcont">
            <h3>2.1 Лепим базовые объемы</h3>
        </div>
        <VideoView video-id="28c63369cf934e0cb0d350cc8d534c2d"/>

        <div class="MBtextcont">
            <h3>2.2 Лепим детали лица</h3>
        </div>
        <VideoView video-id="1e1aec6bb2c1442389385e067f8b7034"/>

        <div class="MBtextcont">
            <h3>2.3 Соединяем заготовки</h3>
        </div>
        <VideoView video-id="7ca4bead0b2f4ee998b77704c548e0d7"/>

        <div class="MBtextcont">
            <h3>2.4 Соединяем голову</h3>
        </div>
        <VideoView video-id="b87375a0caf6406c9b59d4dee870bc2a"/>


    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
            return {
                items: [
                    { title: "Делаем заготовки", url:"/mycourses/childhead/1"},
                    { title: "Лепим лицо", url:"/mycourses/childhead/2"},
                    { title: "Дорабатываем детали", url:"/mycourses/childhead/3"}
                ],
            }
        }
    }
</script>